// main app colors
$color-green: #54b689;
$color-black: black;
$color-yellow: #1f7abccc;
$color-grey-accent: #f5f5f5;

$minimum-width: 350px;
$media-query-tablet: 800px;
$media-query-desktop: 1200px;

$font-paragraph: "Helvetica Neue", "Montserrat", sans-serif, Tahoma, Geneva, Verdana, sans-serif;
$font-heading: "Open Sans", sans-serif, Cambria, Cochin, Georgia,
  "Times New Roman", Times, serif;

$font__size-xs: 1rem / 1.3;
$font__size-sm: 1rem / 1.15;
$font__size_base: 16px;
$font__size-lg: 1rem * 1.15;
$font__size-xl: 1rem * 1.3;
$font__size-xxl: 1rem * 1.6;
$font__size-xxxl: 1rem * 2;

$font__line-height-body: 1.5;
$font__line-height-heading: 1.4;

$container-top-padding: 2rem;
$main-container-top-padding: 3rem;
$container-side-mobile-padding: 0.825rem;

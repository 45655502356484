// header
.header-con {
  position: relative;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 225px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px gray;
  max-width: 100%;
  
  .header-background-img {
    overflow: hidden;
    object-fit: cover;
    width: 150%;
      }

  .header-content-child-con {
    position: absolute;

    .designation-h1 {
      text-transform: capitalize;
      margin-bottom: 0;
      font-size: 1.3rem;
      text-align: center;
      line-height: normal;
      @media only screen and (min-width: $media-query-tablet) {
        font-size: 2rem;
      }
    }

    .designation-p {
      text-transform: capitalize;
      margin-bottom: 0;
      text-align: center;
      line-height: normal;
      font-weight: 400;
      font-size: 0.7rem;
      display: flex;
      justify-content: center;
      @media only screen and (min-width: $media-query-tablet) {
        font-size: 0.9rem;
      }
    }

    .name-con {
      display: none;
      @media only screen and (min-width: $media-query-tablet) {
        display: block;
      }
    }

    .name-con,
    .email-con {
      text-align: center;
      .name-h3 {
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1;
      }
    }

    .email-con {
      .email-p {
        font-size: 0.8rem;
        padding-top: 0.1rem;
      }
    }

    //  social links nav
    .social-links-nav {
      display: flex;
      justify-content: center;
      margin-top: -20px;
      ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding-left: 0;
        margin-top: 1.5rem;
        margin-right: 1rem;
        .icon-link {
          margin-left: 1rem;
        }
        .fab {
          color: white;
          font-size: 1.7rem;
          transition: all 0.5s;
        }
        .fab:hover {
          color: $color-green;
          transform: rotate(360deg);
        }
      }
    }
  }
}

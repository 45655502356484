.footer-con {
  background-color: $color-grey-accent;
  background: url("../images/footer_backgrond.png");
  margin-top: 7rem;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 0.5rem 3rem;
  background-position: center;
  .title-con {
    margin-top: $container-top-padding;
    .title-h3 {
      text-align: center;
      color: white;
      span {
        color: $color-green;
        text-transform: uppercase;
        font-size: 130%;
      }
      &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 700;
        content: "\f1d8";
      }
    }
  }
  .contact-links-nav {
    margin-top: $container-top-padding;
    margin-bottom: $container-top-padding;
    display: flex;
    justify-content: center;
    ul {
      list-style-type: none;
      li {
        margin-top: 0.375rem;
      }
      li:hover a {
        color: $color-green;
      }
      a {
        text-decoration: none;
        color: white;
        font-size: 0.925rem;
        &:hover {
          color: $color-green;
        }
      }
    }
  }
}



.footer-nav-con-active {
  display: block;
  transition: all 0.3s;
  position: fixed;
  margin-top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  @media only screen and (min-width: $media-query-tablet) {
    max-width: 500px;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
  }
  margin: auto;
  max-width: 100%;
  z-index: 10000;
  background-color: rgb(255, 163, 104);
  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-left: 0;
    justify-content: space-between;
    li {
      
      cursor: pointer;
      a {
        display: flex;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: black;
        font-size: 0.775rem;
        transition: all 0.4s;
        padding: 0.5rem;
        @media only screen and (min-width: $media-query-tablet) {
          font-size: 0.875rem;
        }
      }
    }
    li:hover {
      background-color: $color-green;
      border-top-right-radius: 0.0rem;
      border-top-left-radius: 0.0rem;
      a {
        color: white;
        width: 100%;
        border-top-left-radius: 0.7rem;
      }
      @media only screen and (min-width: $media-query-tablet) {
        border-top-right-radius: 0.7rem;
        border-top-left-radius: 0.7rem;
      }
    }
    li a:first-child {
      @media only screen and (min-width: $media-query-tablet) {
        border-top-left-radius: 0.7rem;
      }
    }
    li a:last-child {
      @media only screen and (min-width: $media-query-tablet) {
        border-top-right-radius: 0.7rem;
      }
    }
  }
}

.footer-nav-con-disable {
  display: none;
  transition: all 0.3s;
  
}

/* 
 -----------------------------------------------------------------------------
*/

// Resume view

.main-resume-con-active {
  display: grid;
  grid-template-rows: 50px auto 50px;
  grid-gap: 1rem;
  max-width: $media-query-tablet;
  min-width: $minimum-width;
  margin: auto;
  .cross-con {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    background: #54b689;
    .fas {
      font-size: 1rem;
      margin-right: 1rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        font-size: 1.725rem;
      }
    }
    .resume-p {
      margin-left: 10px;
      font-weight: 700;
    }
  }
  .resume-img-con {
    margin: 0 0.3rem 0;
    padding: 0.3rem;
    border: 2px solid $color-green;
  }
  .download-btn {
    width: 100%;
    cursor: pointer;
    padding: 0.7rem;
    background-color: $color-grey-accent;
    border: none;
    text-transform: capitalize;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    color: black;
    font-weight: 700;
  }
}

// hide the resume view
.main-resume-con-disable {
  display: none;
}

/* 
 -----------------------------------------------------------------------------
*/

// grid layout to root div tag
.super-parent-con-active {
  display: grid;
  @media only screen and (min-width: $media-query-tablet) {
    grid-template-columns: 280px auto;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }

  // main side bar
  .main-top-nav-con {
    background-color: $color-green;
    color: white;
    .minimized {
      height: 75px;
      transition: height 1s;
    }
    .maximized {
      height: 500px;
    }
    .side-bar-con {
      // cross sign, to close nav in mobile view
      .cross-con {
        padding: 1rem;
        padding-top: 1.65rem;
        position: absolute;
        @media only screen and (min-width: $media-query-tablet) {
          display: none;
        }
        .fa-bars {
          font-size: 1.5rem;
        }
      }
      // main content of bar
      .content-con {
        margin: auto;
        padding: 0.925rem;
        display: flex;
        @media only screen and (min-width: $media-query-tablet) {
          justify-content: center;
        }
        height: 100vh;
        flex-direction: column;
        .name-con {
          text-align: center;
          letter-spacing: 0.125rem;
          text-transform: uppercase;
          .name-h1 {
            margin-top: 0;
          }
        }
        .active {
          display: block;
        }
        .image-con {
          text-align: center;
          margin-top: 1rem;
          margin-bottom: 1rem;
          img {
            border-radius: 50%;
            width: 152px;
            height: 152px;
            object-fit: cover;
            border: solid white 3px;
            padding: 0.2rem;
          }
        }
        .info-con {
          text-align: center;

          justify-content: center;
          .info-p {
            font-size: 0.875rem;
            font-weight: 700;
            font-style: normal;
          }
        }
        .resume-con {
          text-align: center;
          margin-top: $container-top-padding;
          display: flex;
          flex-direction: column;
          margin: $container-top-padding 1rem 0;

          button {
            border: solid 2px white;
            background-color: transparent;
            color: white;
            font-weight: 700;
            font-size: 0.925rem;
            padding: 0.5rem 1.5rem 0.5rem;
            border-radius: 0.2rem;
            cursor: pointer;
            .hire-a {
              text-decoration: none;
              color: white;
            }
            .download-a {
              text-decoration: none;
              color: $color-green;
            }
          }
          .view-resume-btn {
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 700;
              content: "\f1e5";
            }
          }
          .download-resume-btn,
          .hire-me-btn {
            margin-top: 0.5rem;
          }
          .download-resume-btn {
            background-color: white;
            color: $color-green;
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 700;
              content: "\f0c7";
            }
          }
          .view-cv-btn {
            background-color: white;
            color: $color-green;
            margin-top: 0.5rem;
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 700;
              content: "\f0c7";
            }
          }
          .hire-me-btn {
            background-color: rgba(0, 0, 0, 0.3);
            &::before {
              font-family: "Font Awesome 5 Free";
              font-weight: 700;
              content: "\f1d8";
            }
          }
        }
      }
    }
  }
  .main-parent-con {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $media-query-tablet) {
      overflow-y: scroll;
    }
    min-width: 350px;
    background-color: white;
  }
}

.super-parent-con-disable {
  display: none;
}

/* 
 -----------------------------------------------------------------------------
*/

// main container

.main-con {
  @media only screen and (min-width: $media-query-tablet) {
    max-width: 600px;
    margin: auto;
  }
  @media only screen and (min-width: $media-query-desktop) {
    max-width: 1200px;
  }
}

/* 
 -----------------------------------------------------------------------------
*/

// welcome part

.welcome-con {
  display: flex;
  flex-direction: column;
  .about-me-con,
  .expertise-con {
    margin-top: $main-container-top-padding;
    margin-left: $container-side-mobile-padding;
    margin-right: $container-side-mobile-padding;
  }
  .about-me-con {
    .title-h4 {
      text-align: center;
      text-transform: uppercase;
    }
    .info-p {
      margin-top: $container-top-padding;
      padding: 0 0.375rem 0;
      font-size: 1rem;
      text-align: justify;
      .keywords {
        // font-weight: 700;
        // color: $color-green;
        border-radius: 0.1rem;
        background-color: #54b689;
        color: white;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
      }
      @media only screen and (min-width: $media-query-desktop) {
        max-width: 1100px;
        margin: auto;
        margin-top: 1rem;
        text-align: center;
      }
    }
    .avaliablity-p {
      margin-top: 1rem;
    }
    .divider {
      margin-top: 1rem;
      height: 0.5px;
      border: none;
      background-color: grey;
      @media only screen and (min-width: $media-query-tablet) {
        margin: auto;
        margin-top: 1rem;
      }
      @media only screen and (min-width: $media-query-desktop) {
        max-width: 1100px;
      }
    }
  }
  .expertise-con {
    .title-h4 {
      text-transform: uppercase;
      text-align: center;
    }
    .expertise-main-con {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-gap: 1rem;
      margin-top: $container-top-padding;
      .info-con {
        border: solid 1px gray;
        border-radius: 0.175rem;
        .image-con {
          max-width: 62px;
          height: auto;
          margin: auto;
          padding-top: 2rem;
          padding-bottom: 2rem;
          img {
            // filter: grayscale(0.5);
          }
        }
        .title-h5 {
          text-align: center;
        }
        .info-p {
          padding: 1rem;
          text-align: center;
        }
      }
      @media only screen and (min-width: $media-query-desktop) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
      }
    }
  }
}

/* 
 -----------------------------------------------------------------------------
*/

// Languages

.language-con {
  margin-top: $container-top-padding;
  margin-left: 0.825rem;
  margin-right: 0.825rem;
  .title-con {
    .title-h4 {
      text-transform: uppercase;
      color: gray;
      text-align: center;
    }
  }
  .content-con {
    display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media only screen and (min-width: $media-query-tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (min-width: $media-query-desktop) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    grid-template-rows: auto;
    margin-top: $container-top-padding;
    grid-gap: 1rem;
    div {
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 0.8rem;
    }
    .language-logo-img {
      width: 52px;
      height: 52px;
    }
  }
}

/* 
 -----------------------------------------------------------------------------
*/

// featured work

.featured-work-con {
  background-color: $color-grey-accent;
  margin-top: $main-container-top-padding;
  padding-bottom: $container-top-padding;
  .divider-hr {
    margin: $container-top-padding 0 $container-top-padding 0;
    height: 1px;
    border: none;
    background-color: grey;
  }
  .featured-work-title-con {
    padding: $container-top-padding 0 $container-top-padding;
    text-align: center;
    text-transform: uppercase;
    @media only screen and (min-width: $media-query-desktop) {
      margin-bottom: 2rem;
    }
  }
  .featured-work-subject-con {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    .info-con {
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      .title-con {
        display: flex;
        justify-content: space-between;
        .date-p {
          font-size: 0.8rem;
          color: grey;
        }
      }
      .website-link-a {
        text-decoration: none;
        color: $color-green;
        font-size: 0.9rem;
      }
      .website-link-a::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 700;
        content: "\f0ac";
      }
      .link-android::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 700;
        content: "\f3cd";
      }
      .info-p {
        margin-top: 1rem;
        font-size: 0.9rem;
        .note-span {
          color: gray;
        }
      }
      .tags-nav {
        margin-top: 1.5rem;
        ul {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0;
          justify-content: flex-end;
          li {
            color: whitesmoke;
            margin-left: 0.3rem;
            font-size: 0.8rem;
            padding: 0.1rem 0.2rem 0.1rem;
            border-radius: 0.2rem;
            background-color: $color-green;
            margin-top: 0.3rem;
          }
          li:first-child {
            margin-left: 0;
          }
        }
      }
      @media only screen and (min-width: $media-query-desktop) {
        padding-left: 3rem;
      }
    }
    @media only screen and (min-width: $media-query-desktop) {
      max-width: 1100px;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }
  .image-con {
    margin: auto;
    img {
      max-width: 300px;
      padding: 0 1rem 0;
      display: flex;
      margin: auto;
      @media only screen and (min-width: $media-query-tablet) {
        max-width: 50%;
      }
      @media only screen and (min-width: $media-query-desktop) {
        max-width: 100%;
      }
    }
    @media only screen and (min-width: $media-query-desktop) {
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (min-width: $media-query-desktop) {
    border-radius: 0.175rem;
    margin-left: 0.825rem;
    margin-right: 0.825rem;
  }
}

/* 
 -----------------------------------------------------------------------------
*/

// full stack layout

.stack-con {
  margin-top: $main-container-top-padding;
  margin-left: 0.825rem;
  margin-right: 0.825rem;
  .stack-h3 {
    text-align: center;
    text-transform: uppercase;
  }
  .stack-content-con {
    @media only screen and (min-width: $media-query-tablet) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 3rem;
    }
    @media only screen and (min-width: $media-query-desktop) {
      //grid-template-columns: 1fr 1fr 1fr;
      .first-block {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 3rem;
      }
      .second-block {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 5rem;
      }
    }
    .stack-title-h5 {
      text-align: center;
      color: grey;
      margin-top: 3rem;
    }
    .mobile-con {
      margin-top: 1rem;
    }
    .subject-con {
      display: flex;
      justify-content: space-around;
      margin-top: 2rem;
      p {
        font-size: 0.7rem;
        text-align: center;
      }
      .tech-con {
        display: "flex";
        flex-direction: "column";
        img {
          display: block;
          margin: auto;
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}

// work experience

.work-exp-con {
  margin-top: $main-container-top-padding;
  .work-exp-h4 {
    text-align: center;
    text-transform: uppercase;
  }
  .temp-con {
    background-color: $color-grey-accent;
    padding: 1rem;
    margin-top: $container-top-padding;
    border-radius: 0.175rem;
    .desi-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
      @media only screen and (min-width: $media-query-tablet) {
        display: block;
      }
    }
    .company {
      font-weight: 600;
    }
    .date {
      font-size: 0.8rem;
      color: grey;
    }
  }
  .exp-content-con {
    margin: 0 $container-side-mobile-padding 0;
    @media only screen and (min-width: $media-query-tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
    @media only screen and (min-width: $media-query-desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  #line1 {
    margin: 76px $container-side-mobile-padding 0;
    width: 70%;
  }
  @media only screen and (min-width: $minimum-width) {
    .horizontal-line {
      border-top: 5px solid $color-grey-accent;
      left: 10%;
      position: absolute;
      visibility: hidden;
    }
  }
  @media only screen and (min-width: 870px) {
    .horizontal-line {
      border-top: 5px solid $color-grey-accent;
      left: 10%;
      position: absolute;
      visibility: visible;
    }
    #line2 {
      margin: 228px $container-side-mobile-padding 0;
      width: 70%;
    }
  }
  @media only screen and (min-width: $media-query-desktop) {
    .horizontal-line {
      border-top: 5px solid $color-grey-accent;
      left: 10%;
      position: absolute;
      visibility: visible;
    }
    #line2 {
      margin: 228px $container-side-mobile-padding 0;
      width: 30%;
    }
  }
}

.demo-btn {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px $color-green;
  margin-top: 1rem;
  display: flex;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    border-color: black;
  }
  a {
    text-decoration: none;
    color: $color-green;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .icon-demo {
    color: $color-green;
  }
  &:hover a {
    color: black;
  }
  &:hover .icon-demo {
    color: black;
  }
}

.demo-btn-disabled {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px grey;
  margin-top: 1rem;
  display: flex;
  transition: all 0.2s;
  cursor: not-allowed;
  &:hover {
    border-color: black;
  }
  a {
    text-decoration: none;
    color: grey;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: not-allowed;
  }
  .icon-demo {
    color: grey;
  }
}

.fa-bolt {
   font-size: 10px;
   margin-left: 2px;
   color: grey;
}

.coming-soon-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
}
.coming-soon-p {
  font-size: 11px;
  color: grey;
  margin-left: 5px;
}

.link-email {
  text-decoration: none;
  font-style: normal;
  color: $color-green;
  font-size: 0.925rem;
}

.progress-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%; 
  background-color: #3b8060;
  z-index: 9999; 
}